<script setup lang="ts">
import type { SearchResult } from '~/codegen/graphql'
import { ReviewType, SearchResultType } from '~/codegen/graphql'
import { GetSearchResultsDocument } from './Search.generated'

const { hidden = false } = defineProps<{
  hidden?: boolean
}>()

const searchOpen = ref(false)
const searchItemElements = useTemplateRef('searchItemElements')
const currentFocusIndex = ref(-1)
const { metaSymbol } = useShortcuts()
defineShortcuts({
  meta_k: {
    usingInput: true,
    handler: () => {
      searchOpen.value = true
    },
  },
  arrowdown: {
    usingInput: true,
    whenever: [searchOpen],
    handler: () => {
      if (!searchItemElements.value)
        return
      if (currentFocusIndex.value < searchItemElements.value?.length - 1) {
        currentFocusIndex.value++
        searchItemElements.value[currentFocusIndex.value]?.$el.focus()
      }
    },
  },
  arrowup: {
    usingInput: true,
    whenever: [searchOpen],
    handler: () => {
      if (!searchItemElements.value)
        return
      if (currentFocusIndex.value > 0) {
        currentFocusIndex.value--
        searchItemElements.value[currentFocusIndex.value]?.$el.focus()
      }
    },
  },
})

const searchTerm = ref('')
const debouncedSearchTerm = refDebounced(searchTerm, 300)
const searchType = ref<SearchResultType | null>(null)
const cursor = ref<string | null | undefined>(null)

const { data, isFetching, onData, execute } = useQuery({
  query: GetSearchResultsDocument,
  paused: computed(() => {
    return !searchOpen.value
  }),
  variables: computed(() => ({
    term: debouncedSearchTerm.value,
    resultType: searchType.value,
    after: cursor.value,
  })),
  cachePolicy: 'network-only',
})

const searchResults = ref<SearchResult[]>([])
const hasMoreResults = computed(() => data.value?.search.pageInfo.hasNextPage)
const isLoadMore = ref(false)

onData((data) => {
  if (isLoadMore.value) {
    searchResults.value.push(...getListOfNodes(data?.search))
  }
  else {
    searchResults.value = getListOfNodes(data?.search)
  }
})

function toggleFilter(type: SearchResultType) {
  if (searchType.value === type)
    searchType.value = null
  else
    searchType.value = type
}

const filterButtons = computed(() => [
  {
    label: 'Reviews',
    icon: 'light:file',
    cb: () => toggleFilter(SearchResultType.Review),
    active: searchType.value === SearchResultType.Review,
  },
  {
    label: 'Surveys',
    icon: 'light:clipboard-check',
    cb: () => toggleFilter(SearchResultType.Survey),
    active: searchType.value === SearchResultType.Survey,
  },
  {
    label: 'Dashboards',
    icon: 'light:square-poll-vertical',
    cb: () => toggleFilter(SearchResultType.Dashboard),
    active: searchType.value === SearchResultType.Dashboard,
  },
  {
    label: 'Teamspaces',
    icon: 'light:folder',
    cb: () => toggleFilter(SearchResultType.Workspace),
    active: searchType.value === SearchResultType.Workspace,
  },
])

watch([searchOpen, searchTerm, searchType], async () => {
  cursor.value = null
  isLoadMore.value = false
  currentFocusIndex.value = -1
})

watch(searchOpen, (value) => {
  if (value) {
    searchResults.value = []
    searchTerm.value = ''
    searchType.value = null
    currentFocusIndex.value = -1
    execute()
  }
})

const openHistoryModal = ref(false)
const openCopyModal = ref(false)
const historyArtifactId = ref('')
const historyArtifactTitle = ref('')
const historyArtifactType = ref<ReviewType>(ReviewType.Review)
const historyTeamspaceId = ref('')

function handleHistoryOpen(id: string, label: string, type: SearchResultType, teamspaceId: string) {
  historyArtifactId.value = id
  historyArtifactTitle.value = label
  historyArtifactType.value = type === SearchResultType.Review ? ReviewType.Review : ReviewType.Survey
  historyTeamspaceId.value = teamspaceId
  openHistoryModal.value = true
}

function handleCopyArtifactAndCloseHistory() {
  openHistoryModal.value = false
  openCopyModal.value = true
}

async function handleLoadMore() {
  isLoadMore.value = true
  cursor.value = data?.value?.search.pageInfo.endCursor
}

const isFiltered = computed(() => Boolean(debouncedSearchTerm.value || searchType.value))
const showLoader = computed(() => isFetching.value && !isLoadMore.value)
const showNoResults = computed(() => isFiltered.value && !searchResults.value.length)
const showStartSearching = computed(() => !isFiltered.value && !searchResults.value.length)
const showRecent = computed(() => !debouncedSearchTerm.value && searchResults.value.length)
</script>

<template>
  <div :class="{ 'flex flex-1': !hidden }">
    <UButton
      v-if="!hidden"
      size="sm"
      label="Search Sleuth"
      icon="light:magnifying-glass"
      variant="ghost"
      color="gray"
      data-action="search-button"
      block
      :ui="{ base: 'bg-blue-90 dark:bg-gray-900 !justify-start' }"
      @click="searchOpen = true"
    >
      <template #trailing>
        <div class="ml-auto flex items-center gap-0.5 opacity-40">
          <UKbd size="xs">
            {{ metaSymbol }}
          </UKbd>
          <UKbd size="xs">
            K
          </UKbd>
        </div>
      </template>
    </UButton>

    <UModal v-model="searchOpen" :ui="{ width: 'w-full sm:max-w-3xl h-[540px]' }">
      <div class="flex h-full flex-col">
        <div class="flex flex-col p-4">
          <UInput v-model="searchTerm" class="mb-4" icon="light:magnifying-glass" placeholder="Search" autofocus />
          <div class="flex items-center gap-2">
            <UButton
              v-for="filter in filterButtons"
              :key="filter.label"
              :label="filter.label"
              :icon="filter.icon"
              size="sm"
              :color="filter.active ? 'primary' : 'gray'"
              class="!rounded-full"
              data-action="search-filter-button"
              @click="filter.cb"
            />
          </div>
        </div>
        <UDivider />
        <div v-if="showLoader" class="flex flex-1 items-center justify-center">
          <!-- Negative margin to optically align it to the middle -->
          <LoadingSpinner class="-mt-10 size-12" />
        </div>
        <div v-else-if="showNoResults" class="flex flex-1 items-center justify-center">
          <div class="-mt-10 text-gray-500 dark:text-gray-400">
            No results found
          </div>
        </div>
        <div v-else-if="showStartSearching" class="flex flex-1 items-center justify-center">
          <div class="-mt-10 text-gray-500 dark:text-gray-400">
            Start typing to search
          </div>
        </div>
        <div v-else class="flex flex-1 flex-col overflow-auto p-4">
          <div v-if="showRecent" class="mb-2 text-sm font-semibold text-gray-500 dark:text-gray-400">
            Recent
          </div>
          <NavigationSearchItem
            v-for="(result, index) in searchResults"
            :id="result.artifactId"
            :key="index"
            ref="searchItemElements"
            :type="result.type"
            :searched-term="debouncedSearchTerm"
            :label="result.artifactName"
            :teamspace-id="result.workspaceId"
            :teamspace-label="result.workspaceName"
            :teamspace-icon="result.workspaceMarker"
            :chain-count="result.chainCount"
            @open-history="handleHistoryOpen"
            @click="searchOpen = false"
          />

          <div v-if="hasMoreResults && !showRecent" class="mt-2 flex items-center justify-center">
            <UButton
              size="sm"
              variant="ghost"
              data-action="search-load-more-button"
              :loading="isFetching && isLoadMore"
              @click="handleLoadMore"
            >
              Load more
            </UButton>
          </div>
        </div>
      </div>

      <ArtifactCopyModal
        v-if="historyArtifactId"
        :id="historyArtifactId"
        v-model="openCopyModal"
        :name="historyArtifactTitle"
        :type="historyArtifactType"
        :workspace-id="historyTeamspaceId"
        @copied="searchOpen = false"
      />
      <ArtifactHistoryModal
        v-if="historyArtifactId"
        :id="historyArtifactId"
        v-model="openHistoryModal"
        :name="historyArtifactTitle"
        :type="historyArtifactType"
        :workspace-id="historyTeamspaceId"
        @copy="handleCopyArtifactAndCloseHistory"
      />
    </UModal>
  </div>
</template>
