<script setup lang="ts">
import type { RouteLocationNormalized } from 'vue-router'
import type { WorkspaceMarkerField } from '~/codegen/graphql'
import { SearchResultType } from '~/codegen/graphql'

const props = defineProps<{
  type: SearchResultType
  id?: string | null
  label?: string | null
  chainCount?: number | null
  teamspaceId: string
  teamspaceLabel: string
  teamspaceIcon: WorkspaceMarkerField
  searchedTerm?: string
}>()

defineEmits<{
  openHistory: [id: string, label: string, type: SearchResultType, teamspaceId: string]
}>()

const isTeamspace = computed(() => props.type === SearchResultType.Workspace)

const icon = computed(() => ({
  [SearchResultType.Review]: 'light:file',
  [SearchResultType.Survey]: 'light:clipboard-check',
  [SearchResultType.Dashboard]: 'light:square-poll-vertical',
  [SearchResultType.Workspace]: null,
})[props.type])

const link = computed(() => {
  if (props.type === SearchResultType.Workspace) {
    return {
      name: 'teamspace-workspaceId',
      params: { workspaceId: props.teamspaceId },
    } as RouteLocationNormalized
  }
  else if (props.id) {
    const type = searchResultTypeToReviewType(props.type)
    return getRouteForArtifact(props.id, props.teamspaceId, type)
  }

  return undefined
})

function highlightSearchTerm(text: string, term?: string) {
  if (!term)
    return text
  return text.replace(
    new RegExp(`(${term})`, 'gi'),
    '<span class="bg-yellow-500/50">$1</span>',
  )
}
</script>

<template>
  <NuxtLink
    :to="link"
    data-action="search-result"
    class="group/link flex items-center gap-2 rounded-md p-2 text-base hover:bg-gray-100 focus:bg-gray-100 dark:hover:bg-gray-800 dark:focus:bg-gray-800"
  >
    <UIcon v-if="icon && !isTeamspace" :name="icon" class="size-4 text-gray-500 dark:text-gray-400" />
    <WorkspaceIcon v-else-if="teamspaceIcon" :marker="teamspaceIcon" />

    <div class="flex-1 truncate">
      <span v-html="highlightSearchTerm(props.label || teamspaceLabel, searchedTerm)" />
    </div>
    <div v-if="!isTeamspace" class="ml-3 flex items-center gap-2 opacity-40 group-hover/link:opacity-100 group-focus/link:opacity-100">
      <div class="flex max-w-60 items-center gap-1 truncate text-sm">
        <WorkspaceIcon if="teamspaceIcon" :marker="teamspaceIcon" size="sm" />
        <div class="truncate">
          {{ teamspaceLabel }}
        </div>
      </div>
      <UTooltip v-if="chainCount && chainCount > 1 && id && label" text="Show history" class="shrink-0">
        <UChip size="md" color="gray" :text="chainCount - 1">
          <UButton
            size="xs"
            color="gray"
            icon="light:rectangle-history"
            class="-my-0.5"
            @click.prevent.stop="$emit('openHistory', id, label, type, teamspaceId)"
          />
        </UChip>
      </UTooltip>
    </div>
  </NuxtLink>
</template>
